.App {
  display: flex;
  flex-direction: column;
  font: "Poppins-Medium", sans-serif;
}

button:focus, a:focus {
  -webkit-tap-highlight-color: transparent;
}

::selection {
  background-color: #000000;
  color: #ffffff;
  -webkit-text-fill-color: #ffffff;
}

.main {
  display: flex;
  flex-direction: row;
}

.left-div {
  flex: 1;
  height: 95vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.card {
  border-radius: 0.75rem;
  border: 4px solid #E6E6E6;
  padding: 15px;
  margin-right: 5vw;
  display: flex;
  flex-direction: column;
  background: #F5F5F5;
  box-shadow: 0px 370px 148px 0px rgba(0, 0, 0, 0.01), 0px 208px 125px 0px rgba(0, 0, 0, 0.05), 0px 93px 93px 0px rgba(0, 0, 0, 0.09), 0px 23px 51px 0px rgba(0, 0, 0, 0.10);
}

.card .text {
  margin-bottom: 5px;
  width: 350px;
  color: #4d4d4d;
  font-family: "Poppins-Light", sans-serif; 
}

.image-placeholder, .image-placeholder img{
  width: 350px;
  height: 350px;
  border-radius: 5px;
}

.card input {
  font-family: "Poppins-Light", sans-serif;
  background: transparent;
  padding: 8px;
  border: none;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
  outline: none;
}

.card .submitted {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px;
  margin-bottom: 15px;
}

.card .submitted a {
  font-family: "Poppins-Light", sans-serif;
  color: #999999;
  text-decoration: none;
}

.card button {
  /* color: #333; */
  font-family: "Poppins-Light", sans-serif;
  padding: 10px 25px;
  border: 1px solid #999999;
  border-radius: 20px;
  cursor: pointer;
  margin-left: auto;
}


.right-div {
  flex: 1;
  height: 95vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: xx-large;
}

.right-div p{
  margin: 0;
}

.right-div .Welcome{
  font-family: "Libre Baskerville", serif;
  font-size: 60px;
  font-style: italic;
}

.right-div .app{
  font-size: 60px;
  font-weight: medium;
}

/* Existing CSS styles */

/* Media query for phones */
@media only screen and (max-width: 600px) {

  .main {
    flex-direction: column-reverse;
  }

  .left-div {
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .card {
    margin-right: 0;
    padding: 10px;
  }

  .card .text {
    width: 80vw; /* Make text full width */
  }

  .image-placeholder,
  .image-placeholder img {
    width: 85vw; 
    height: 85vw;
    border-radius: 5px;
  }

  .card input {
    /* width: 100%; Make input fields full width */
    margin-bottom: 15px; /* Add some space between input fields */
  }

  .right-div {
    align-items: center;
    padding: 15px;
    margin-top: 40px;
  }

  .right-div .Welcome{
    font-size: 12vw; /* Adjust font size */
  }

  .right-div .app {
    font-size: 12vw; /* Adjust font size */
    margin-top: -20px;
  }
}
